.modelessdialog {
  overflow-y: auto;
  overflow-x: hidden;
  resize: both;
  z-index: 200;
  /*transform: scale(1, 1); */
}
.modelessdialog_noresize {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 200;
  /*  transform: scale(1, 1); */
}
.modelessdialog_backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}
.modelessdialog_header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modelessdialog_header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modelessdialog h1 {
  padding: 0;
}

.modelessdialog_title_span {
  margin-left: 0;
}

.modelessdialog_noresize section,
.modelessdialog section {
  padding: 0;
}

.modelessdialog_noresize header,
.modelessdialog header {
  position: sticky;
  top: 0;
  z-index: 100;
}
.modelessdialog_noresize footer,
.modelessdialog footer {
  position: sticky;
  bottom: 0;
  z-index: 100;
}
