
.progress-status {
  display: flex;
}

.progress-status > hr {
  border: 0 dashed rgba(255,255,255,.3);
  border-width: 0 0 0 1px;
  margin: 0 25px 0 24px;
  order: 2;
}

.box-evaluation-comment {
  align-items: flex-start;
  display: flex;
  padding-top: 13px;
}

.box-evaluation-comment > div:first-child {
  flex-grow: 1;
  border-right: 1px dashed rgba(255,255,255,.3);
  padding: 0 24px 34px 0;
}

.box-evaluation-comment > div:last-child {
  flex-grow: 1;
  padding: 0 0 0 20px;
}

.progress-status > input[type='radio'] {
  display: none;
}

.progress-status > label {
  background: radial-gradient(ellipse at center, #565a61 0%,#565a61 50%,transparent 54%,transparent 100%) no-repeat center top 20px,
              radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%) no-repeat center top 18px,
              radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%) no-repeat center top 16px,
              linear-gradient(to right, transparent 0%,transparent 50%,transparent 50%,transparent 100%) no-repeat left top 26px transparent;
  background-size: 20px 20px,
                   24px 24px,
                   28px 28px,
                   100% 8px;
  border: 0 none;
  box-shadow: none;
  box-sizing: border-box;
  display: block;
  line-height: 1;
  margin: 0;
  order: 3;
  padding: 54px 0 0 0;
  position: relative;
  text-align: center;
  width: 54px;
}

.progress-status > input[type='radio'] + label.current::before {
  content: url("data:image/svg+xml;charset=utf-8, %3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E %3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='26.000000pt' height='26.000000pt' viewBox='0 0 108.000000 108.000000' preserveAspectRatio='xMidYMid meet'%3E %3Cmetadata%3E Created by potrace 1.10%2C written by Peter Selinger 2001-2011 %3C/metadata%3E %3Cg transform='translate(0.000000%2C108.000000) scale(0.100000%2C-0.100000)' fill='%23f35116' stroke='none'%3E %3Cpath d='M462 962 c-10 -7 -12 -100 -10 -433 3 -369 5 -424 18 -424 13 0 16 29 21 175 3 96 6 176 7 177 1 1 32 17 70 36 37 19 108 56 157 82 50 26 123 65 164 87 111 57 110 55 49 95 -29 19 -80 47 -113 62 -33 16 -108 52 -167 80 -59 28 -114 51 -122 51 -8 0 -18 5 -21 10 -7 12 -35 13 -53 2z'/%3E %3C/g%3E %3C/svg%3E ");
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-44%,-2px);
}

.progress-status > hr ~ label {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, #0b5fcc 0%,#0b5fcc 50%,#0b5fcc 50%,#0b5fcc 100%);
  order: 1;
  padding-left: 9px;
  padding-right: 8px;
}

.progress-status > input[type='radio']:checked + label {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, transparent 0%,transparent 50%,transparent 50%,transparent 100%);
}

.progress-status > hr ~ input[type='radio']:checked + label {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, #0b5fcc 0%,#0b5fcc 50%,#fff 50%,#fff 100%);
}

.progress-status > hr ~ input[type='radio']:checked + label ~ label {
  background-image: radial-gradient(ellipse at center, #565a61 0%,#565a61 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, #fff 0%,#fff 50%,#fff 50%,#fff 100%);
}

.progress-status > hr ~ input[type='radio']:checked + label ~ label:last-of-type {
  background-image: radial-gradient(ellipse at center, #565a61 0%,#565a61 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, #fff 0%,#fff 50%,transparent 50%,transparent 100%);
}

.progress-status > hr ~ input[type='radio']:checked + label:last-of-type {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, #0b5fcc 0%,#0b5fcc 50%,transparent 50%,transparent 100%);
}

.progress-status > hr + input[type='radio'] + label {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, transparent 0%,transparent 50%,#0b5fcc 50%,#0b5fcc 100%);
}

.progress-status > hr + input[type='radio']:checked + label {
  background-image: radial-gradient(ellipse at center, #0b5fcc 0%,#0b5fcc 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%),
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%),
                    linear-gradient(to right, transparent 0%,transparent 50%,#fff 50%,#fff 100%);
}

.progress-status-grey-right{
  background-image: radial-gradient(ellipse at center, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, transparent 0%,transparent 50%,rgba(195,195,195,1) 50%,rgba(195,195,195,1) 100%)!important;
}

.progress-status-grey-left{
  background-image: radial-gradient(ellipse at center, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 50%,transparent 100%)!important;
}

.progress-status-grey-left-white{
  background-image: radial-gradient(ellipse at center, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,#fff 50%,#fff 100%)!important;
}

.progress-status-grey-whole{
  background-image: radial-gradient(ellipse at center, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, rgba(195,195,195,15) 0%,rgba(195,195,195,1) 50%,rgba(195,195,195,1) 50%,rgba(195,195,195,1) 100%)!important;
}

.progress-status-grey-middle{
  background-image: radial-gradient(ellipse at center, rgba(195,195,195,1) 0%,rgba(195,195,195,1) 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, transparent 0%,transparent 50%,transparent 50%,transparent 100%)!important;
}

.progress-status-together-left{
  background-image: radial-gradient(ellipse at center, #565a61 0%, #565a61 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, transparent 0%, transparent 50%,#fff 50%,#fff 100%)!important;
}

.progress-status-together-whole{
  background-image: radial-gradient(ellipse at center, #565a61 0%, #565a61 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, #fff 0%, #fff 50%,#fff 50%,#fff 100%)!important;
}

.progress-status-together-right{
  background-image: radial-gradient(ellipse at center, #565a61 0%, #565a61 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, #fff 0%,#fff 50%,transparent 54%,transparent 100%), 
                    radial-gradient(ellipse at center, rgba(0,0,0,.4) 0%,rgba(0,0,0,.4) 40%,transparent 70%,transparent 100%), 
                    linear-gradient(to right, #fff 0%, #fff 50%, transparent 50%, transparent 100%)!important;
}

.style-target{
  color : #fff;
  position : absolute;
}

.style-target-label{
  width : 70px;
  height : 25px;
  text-align : center;
  float : right;
  background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg width='72' height='26' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3C!-- Created with Method Draw - http://github.com/duopixel/Method-Draw/ --%3E %3Cg%3E %3Ctitle%3Ebackground%3C/title%3E %3Crect fill='%23fff' id='canvas_background' height='28' width='72' y='-1' x='-1'/%3E %3Cg display='none' overflow='visible' y='0' x='0' height='100%25' width='100%25' id='canvasGrid'%3E %3Crect fill='url(%23gridpattern)' stroke-width='0' y='0' x='0' height='100%25' width='100%25'/%3E %3C/g%3E %3C/g%3E %3Cg%3E %3Ctitle%3ELayer 1%3C/title%3E %3Cimage xlink:href='data:image/png%3Bbase64%2CiVBORw0KGgoAAAANSUhEUgAAAEYAAAAaCAYAAAAKYioIAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAI6SURBVFhH7ZjNigIxEIQzKqJHD77/ayji2ZcRxKP4M2tlKSmbJCaZYWdFPwgzSSfpTk2nZbfZbrftHTcajdwX56AFaNbrdTudTt3lcnG3280PfipN07jJZOJ1aDabTYvOfr93p9PJGz8V3Jrlcuk1eNwfpNCnN02Kp8Lilbqr9g4NsfYZr4oCOldcql1LzXqdn1rbJa5OwqBIpQ6GcRT1kJ2FXtdfr1ff2A%2BtAxhnxsTm%2BAJ6t9f%2BoFQJg2AYEFMxBgp7Cl0/Ho99Y1qHDo0xzGcM6lvnc47aS%2BgkjL2XltQXzSG0PzMANrWHsvdVfCmyhbEObWAxYvNy1oZETQnNLOmDLGHojM9cUVLkrMdBbY2IXU3Oxb59iJMtTF8OSymtEX8qDJzxawwJYrAZpOTEmVqvZAuDL0fHQ2QOQAypDEJc%2BFULxYcxxJ6bgWV5egcbDyXMKxBb7PCIuST2YmFeperQxGLDeMkHrcqYIYXhlcDTHlT71oaY2XIoFuY/oFciJg6e1lZCtTBdnHZBr4S%2BA%2B3jicyqparGABsU0cD0vS9Y47TQMib4gQ1wTi3FK%2BFQA1G0j3fMC/11jXHuAWw/hR5WRYAPNB3jew1PwtgDhKBDHMQ6ZmB8ouEgdh5txPZzwRr1pXvU7Kc8hOEX49dLNQqIp44D2jkH2Hm0x/olze6jtppGHv8MPxwOPu27Kv3OILsXi4UXuFmtVu1sNvOGTxYFQBBwPp9ds9vt2uPx6P/G%2BPJbCubzufsB%2B6QJ1yCe%2B6kAAAAASUVORK5CYII=' id='svg_1' height='26' width='70' y='0' x='0'/%3E %3C/g%3E %3C/svg%3E");
  padding-top : 3px;
  position:relative;
  top:-7px;
}

.style-progress-status{
  height : 100px;
  background-color : #565a61;
  font-size : 90%;
}

.style-progress-status-text-color{
  color : #fff;
}